import React, { useState } from "react"
import axios from "axios"
import styles from "./subscribe.module.css"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const Subscribe = () => {
  const [email, setEmail] = useState("")
  const [gdprAccept, setGdprAccept] = useState(false)

  const [inflight, setInflight] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleCreateRequest = async () => {
    setSuccess(false)
    setError(false)
    setInflight(true)

    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha("contact")

    axios
      .post("https://app.time2client.com/api/new-account", {
        email: email,
        token,
      })
      .then(response => {
        console.log(response)
        setInflight(false)
        setSuccess(true)
      })
      .catch(error => {
        console.log(error)
        setEmail("")
        setInflight(false)
        setError(true)
      })
  }

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Crea il tuo portale clienti</h4>
      <p>
        Con Time2Client puoi avere il tuo portale clienti personalizzato per
        interagire con i tuoi clienti in sicurezza. Registra il tuo account
        gratuitamente.
      </p>
      {success ? (
        <p>
          Grazie per aver creato l'account. Nelle prossime ore riceverà una mail
          per confermare l'indirizzo mail inserito.
        </p>
      ) : (
        <>
          <div className={styles.field}>
            <input
              type="email"
              className={styles.inputEmail}
              placeholder="Inserisci il tuo indirizzo mail"
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            ></input>
          </div>
          <div
            className={styles.field}
            style={{ display: "flex", textAlign: "left", fontSize: "12px" }}
          >
            <input
              id="gdpr"
              style={{ widht: "24px" }}
              type="checkbox"
              checked={gdprAccept}
              onChange={() => {
                setGdprAccept(!gdprAccept)
              }}
            ></input>
            <label htmlFor="gdpr" style={{ flex: 1 }}>
              Consenso GDPR: Acconsento il trattamento dei dati da parte di
              Time2Client per is soli fini della creazione dell'account.
            </label>
          </div>
          <div>
            <button
              onClick={handleCreateRequest}
              disabled={!gdprAccept || !email || inflight}
            >
              Invia
            </button>
          </div>
        </>
      )}
      {error ? (
        <p className={styles.error}>
          Si è verificato un errore. Si prega di riprovare più tardi.
        </p>
      ) : null}
    </div>
  )
}

export default Subscribe

import React from "react"
import Img from "gatsby-image"

import styles from "../components/post.module.css"
import BlockContent from "../components/block_content"

const Post = ({ post }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.postTitle}>{post.title}</h1>
      <Img fluid={post.image.asset.fluid} />
      <div className={styles.photoCredit}>{post.photoCredit}</div>
      <div className={styles.postBody}>
        {post._rawBody && <BlockContent blocks={post._rawBody} />}
      </div>
    </div>
  )
}

export default Post

import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import PostLayout from "../components/postLayout"
import Post from "../components/post"
import SEO from "../components/seo"
import Subscribe from "../components/subscribe"

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      title
      _rawExcerpt
      url {
        current
      }
      photoCredit
      image {
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
          size
          url
          metadata {
            dimensions {
              height
              width
              aspectRatio
            }
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`

const BlogPostTemplate = ({ data }) => {
  const post = data && data.post

  const image =
    post && post.image
      ? {
          src: post.image.asset.url,
          height: `${post.image.asset.metadata.dimensions.height}`,
          width: `${post.image.asset.metadata.dimensions.width}`,
        }
      : null
  return (
    <Layout>
      <SEO title={post.title} image={image} />
      <PostLayout>
        <Post post={post} />

        <Subscribe />
        <Link to="/" style={{ paddingTop: "48px", display: "block" }}>
          Tutti i post
        </Link>
      </PostLayout>
    </Layout>
  )
}

export default BlogPostTemplate
